.first_Letter_bg {
  width: 30px;
  height: 30px;
  /* background-color: #d9d9d9; */
  background-color: var(--bg-light-gray);
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #878787;
  font-weight: 500;
  font-size: 18px;
}
.first_Letter_bg:hover {
  transform: scale(1.15); /* Scale the element to 110% of its size */
}
.project-separation {
  border-bottom: 1px solid var(--border-color);
}

.project-form-separation {
  padding: 0 20px 0 0;
  border-right: 1px solid var(--border-color);
}

.project-heading {
  color: var(--main-text-color, #2a2e34);
  leading-trim: both;
  text-edge: cap;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%; /* 27px */
  margin-bottom: 20px;
  text-transform: capitalize;
}

.project-details {
  margin-top: 10px;
  display: flex;
  gap: 20px;
}

.projectDetailsColumnHead {
  font-size: 13px;
  color: #879096;
}
.projectDetailsColumnBody {
  font-size: 13px;
  color: #383838;
}

.project-layout {
  padding: 10px 25px;
  /* border-bottom: 2px solid var(--border-color);
  margin: '0.3em 1em 0.3em 1em '; */
}

.subheading {
  font-size: 15px;
  margin-top: 10px;
}

.progress-bar-parent {
  /* background-color: #d9d9d9 !important; */
  width: 78px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 100px;
  /* background: rgba(28, 169, 119, 0.20); */
  position: relative;
}

.progress-bar-child {
  /* --progress-width: 30%; Default width */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 100px;

  /* width: var(--progress-width); Use the CSS variable here */
  background-color: red;
}

.user-profile-pic {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.project-table-container {
  max-width: 1100px;
  padding: 16px 24px;
  border-radius: var(--border-radius);
}

.project-table-outer {
  border-radius: 4px;
  padding: 0px 20px 20px 15px;
  width: 75vw;
}

.url-inner-navigation {
  padding: 8px 20px;
  z-index: 9;
  border-bottom: 1px solid var(--border-color);
}

.details-outer {
  /* width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; */
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex-direction: row;
}

.details-heading {
  color: var(--second-text-color, #87909e);
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}

.details-value {
  color: var(--main-text-color, #2a2e34);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.project-tabs-in-active {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0.5px solid var(--border-color, #eaebef);
  border-radius: 4px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  padding: 1px 5px;
  color: var(--second-text-color, #87909e);
  background-color: var(--bg-primary);
  height: fit-content;
  /* background: var(--filter-hover, #E9F2FF); */
}

.project-tabs-active {
  color: #3454d1;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  display: inline-flex;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: fit-content;
  border-radius: 4px;
  background: var(--tab-hover);
}

.project-tabs-in-active:hover {
  /* color: var(--main-text-color); */
  background-color: var(--border-color);
}

.chat-icon-project {
  color: #d2d2d2;
}
.chat-icon-project:hover {
  color: #1c1b1b;
}
.project-chat-count {
  font-size: 10px;
  position: absolute;
  top: -2px;
  background: #f87777;
  padding: 1px 3px;
  border-radius: 50%;
  color: var(--bg-primary);
  right: -6px;
  min-width: 16px;
  min-height: 17px;
  text-align: center;
}

/* project add css  */

.clear-button {
  color: var(--second-text-color, #87909e);
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.list-section {
  max-height: 30vh;
  overflow-y: auto;
}

.user-list-item-display {
  /* color: var(--main-text-color, #2A2E34);
font-family: "Plus Jakarta Sans"; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* If you want to limit to two lines */

.project_menu_check_box {
  min-width: 15px; /* Fixed width */
  min-height: 15px; /* Fixed height */
}

.project_menu_check_box:checked {
  accent-color: black;
}

/* input box  */

.field-input-field {
  width: 100%;
  border: 0.5px solid var(--second-text-color);
  border-radius: 3px;
  height: 34px;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 17.64px;
}
.field-input-field:focus {
  border: 1px solid #3454d1;
  outline: none; /* Optional: removes the default outline */
}

/* filter  */
.filter-field-name {
  font-weight: 500;
  font-size: 0.8125rem;
  margin-bottom: 3px;
}

.project-image-outer {
  border-radius: 100%;
  width: 72px;
  height: 70px;
}

.logo-add-plus-btn-bg {
  background: var(--Base-Color);
  border-radius: 16px;
  width: 18px;
  position: absolute;
  left: calc(100% - 19px);
  top: calc(100% - 18px);
  align-items: center;
  display: flex;
}
.project-add-container {
  /* padding: 15px 20px;
  display: flex;
  max-height: 500px;
  overflow: auto; */
  padding: 15px 20px 0px 20px;
  display: flex;
  max-height: 500px;
  background: var(--bg-primary);
  overflow: auto;
  margin: 0px 20px 0px 20px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
}
.project-input-row {
  display: flex;
  font-weight: 500;
  gap: 23px;
}
.project-single-header-container {
  padding: 20px 24px;
}
.task-list-milestone-select {
  /* padding-left: 24px; */
  max-width: 300px;
}
.project-layout .task-list-container {
  padding: 0px !important;
}
.project-layout .task_tabs {
  padding: 0px !important;
}
.project-layout .task_tab_contain {
  /* padding: 8px 8px 0px 9px !important; */
  padding: 2px 8px;
}
.project-milestone-header-container {
  padding: 5px 0px;
}
.project-pin-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
/* .contact-add-icon{
  padding: 1px 3px;
  background: var(--second-text-color);
  border-radius: 100%;
} */
@media (max-width: 768px) {
  .project-details {
    display: flex;
    flex-direction: column;
    min-height: 250px;
  }
  .project-table-container {
    max-width: 100% !important;
    /* margin: 20px 18px; */
    border: 0px;
    padding: 0px;
    height: 100vh;
    overflow: auto;
  }
  .project-table {
    margin-top: 185px;
    padding: 17px;
    position: relative;
    height: 74vh;
    padding-bottom: 22px;
  }
  .table-responsive-td {
    padding-left: 17px !important;
  }
  .project-add-container {
    flex-direction: column;
    max-height: max-content;
    padding: 15px 18px;
  }
  .project-input-row {
    display: block;
  }
  .project-form-separation {
    padding: 10px 0px;
    border: 0;
    border-bottom: 1px solid var(--border-color);
  }
  .project-single-header-container {
    padding: 10px 17px;
    margin-top: 98px;
  }
  .project-layout {
    padding: 10px 0px;
  }
  .task-list-milestone-select {
    padding-left: 17px;
  }
  .url-inner-navigation {
    position: absolute;
    width: 100%;
    z-index: 9;
  }
  .project-layout .tab_contain {
    position: relative;
    top: -14px;
    z-index: 0;
  }
  .project-layout .project-single-header-container {
    top: 95px;
    position: relative;
    padding: 17px;
  }
  .project-layout .org-nav-contain {
    padding: 0px 17px;
  }
  .project-layout .task_tabs {
    /* padding: 0px 17px !important;
    margin-top: 325px;
    z-index: 0; */
    padding: 0px 17px !important;
    /* margin-top: 325px; */
    z-index: 0;
    position: sticky !important;
    top: 175px;
  }
  .project-layout .table-container {
    padding: 0px 17px !important;
    margin-top: 10px;
    top: 0px;
  }
  .project-milestone-header-container .table_outer {
    padding: 0px 17px !important;
  }
  .project-layout .calendar-wrap {
    padding: 10px 16px;
    margin-top: -24px;
  }
  .project-layout .time-box-filter {
    padding: 10px 16px;
  }
  .project-layout .tab_contain {
    position: sticky !important;
    top: 99px !important;
    z-index: 3;
    margin: 0px;
  }
  .project-layout .tab_contain.no_task {
    position: relative !important;
    top: -11px !important;
  }

  .project-layout .board-container {
    position: relative !important;
    top: -16px !important;
  }
  .form-button-container {
    position: sticky;
    bottom: 0px;
    background: #f5f5f5;
  }
}

.client-edit-icon {
  color: var(--second-text-color);
}
.client-edit-icon:hover {
  color: var(--main-text-color);
}
