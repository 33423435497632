/* .modal_view {
  width: 75vw;
  width: 75vw;
} */

.table {
  color: var(--main-text-color);
}
.table th {
  font-size: 13px;
  color: var(--main-text-color);
  text-align: left;
}
.table.tbl tbody tr:last-child td {
  border-bottom: 1px solid var(--border-color); /* Add border-bottom to last row's cells */
}
.table.tbl tbody td {
  /* vertical-align: middle; */
  border-bottom: 1px solid var(--border-color); /* Add border-bottom to last row's cells */
}
.table.tbl thead th {
  border-bottom: 1px solid var(--border-color); /* Add border-bottom to last row's cells */
}

.table.tbl thead th {
  border-bottom: 1px solid var(--border-color) !important;
}
.add_contain {
  gap: 6px;
  padding: 8px 0px 8px 0px;
  display: flex !important;
  align-items: center;
}
.btn {
  font-family: Inter;
  letter-spacing: -0.02em;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  color: var(--btn-text-color);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
  background: var(--btn-bg-light);
  padding: 4px 6px t;
  border-radius: 8px;
  box-shadow: var(--button-shadow, 0px 2px 4px rgba(0, 0, 0, 0.1));
}
.btn:hover {
  background-color: var(--btn-hover, #f7f8f9);
  color: var(--btn-text-color);
}
.btn svg {
  width: 12px;
  height: 12px;
}
.btn_txt {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 2px;
}
.cancel-btn,
.status-del-cancel {
  font-family: Inter;
  letter-spacing: -0.02em;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  color: var(--btn-text-color);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
  background: var(--bg-light-gray);
  border: 1px solid var(--border-color);
  padding: 4px 6px;
  outline: none;
  border-radius: 8px;
}

.btn_font {
  font-size: 13px;
  font-weight: 600;
}
.btn-wrapper {
  --wrapper-left: -5px;
  color: var(--icon-light);
  user-select: none;
  position: relative;
  cursor: pointer;
  height: fit-content;
}
.btn-wrapper:hover {
  color: var(--text-color);
}
.btn_img {
  height: 16px;
}

.popover-item {
  transition: left 1ms ease-in-out, top 200ms ease-in-out;
}

.drop_down {
  position: absolute;
  top: calc(var(--top-bar-height) - 17px);
  right: 0;
  background-color: var(--bg-primary);
  color: var(--icon-light);
}

.tb_plc {
  padding-left: 15px !important;
  cursor: pointer;
}

thead th:first-child {
  padding-left: 20px;
  width: 4rem;
  border-radius: 4px 0px 0px 0px;
}

tbody td:first-child {
  padding-left: 18px; /* Adjust the padding as needed */
}
.no-padding thead th:first-child,
.no-padding tbody td:first-child {
  padding-left: 15px;
  /* width: 0rem; */
}
.th {
  width: fit-content !important;
}
.th-content {
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 13px;
}

.search_contain {
  box-shadow: var(--input-border-shadow);
  padding: 2px 7px 2px 7px;
  display: flex;
  align-items: center;
  border-radius: 17px;
  gap: 5px;
  height: 34px;
  width: 34px;
  background: transparent;
  justify-content: center;
  transition: width 0.3s ease, border 0.3s ease;
}

.search_contain input {
  background: transparent;
  display: none;
}
.search_contain svg {
  width: 16px;
  height: 16px;
}

/* .search_contain:hover,
.search_contain:focus-within,
.search_contain input:not(:placeholder-shown) {
  width: 184px;
} */

/* .search_contain:hover input,
.search_contain.active input,
.search_contain input:focus {
  display: block;
} */
/* .search_contain:hover input,
.search_contain input:not(:placeholder-shown),
.search_contain input:focus {
  display: block;
} */
.search_contain:hover,
.search_contain:focus-within,
.search_contain.has-value {
  width: 184px; /* Expanded width */
}

.search_contain:hover input,
.search_contain input:focus,
.search_contain.has-value input {
  display: block;
}

.bodr_none {
  border: none;
  outline: none;
  font-size: 13px;
  width: 100%;
}

.search_contain:focus-within {
  /* border: 1px solid #3454d1;
  outline: 3px solid rgba(52, 84, 209, 0.25); Use outline instead of box-shadow */
  border: 1.5px solid #3454d1;
}
/* .search_contain input:not(:placeholder-shown) {
  display: block;
} */

.pagination_parent_contain {
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.task_editor {
  /* line-height: 14px !important; */
  font-size: 14px;
}

.page_active {
  display: flex;
  background: var(--main-gray);
  padding: 2px;
  border-radius: 4px;
  height: 23px;
  width: 23px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--bg-primary);
}

.page_in_active {
  display: flex;
  /* background: #d2d2d2; */
  padding: 2px;
  border-radius: 4px;
  height: 23px;
  width: 23px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  color: #656f7d;
  /* opacity: 0.5; */
}
.page_in_active:hover {
  background-color: var(--btn-hover);
}

.pagination_contain {
  margin: 0px;
  gap: 8px;
  display: flex;
  padding-left: 0;
  list-style: none;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.page_txt {
  color: var(--second-text-color);
  font-size: 13px;
}

.project_add_contain {
  width: 50%;
  height: 50%;
  background-color: var(--bg-primary);
  margin-top: 3%;
  border-radius: 5px;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 13px 6px 15px;
  background: var(--bg-light);
  height: 50px;
  border-bottom: 1px solid var(--bg-light-gray);
}
.menu-item-head {
  font-size: 16px;
  font-weight: 600;
  color: var(--icon-light);
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
}

.modal-end-content {
  margin: 0 16px;
  flex: 1;
}

.modal-end-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.settings-page {
  padding: 0px 15px 0px 15px;
  margin: 20px;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  height: 88vh;
  overflow: hidden;
}

.settings_head {
  display: flex;
  gap: 5px;
  position: relative;
  /* margin-top: 10px; */
  flex-wrap: nowrap;
  /* overflow-x: scroll; */
  flex-direction: column;
  margin: 19px 0px;
  overflow-y: auto;
  /* max-height: (100vh - 20px - 16px); */
}

.menu_active {
  border-bottom: 1.8px solid #3454d1;
  /* padding: 0px 2px 8px 2px; */
  color: var(--main-text-color, #2a2e34);

  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
.menu_inactive {
  color: var(--second-text-color, #87909e);

  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* color: black; */
  text-decoration: none;
  /* background: red; */
  padding: 0px 2px 8px 2px;
}
.settings_head .menu_active,
.settings_head .menu_inactive {
  cursor: pointer;
  white-space: nowrap;
}
.menu_inactive:hover {
  color: var(--text-color);
  opacity: 0.9;
}
.menu_line {
  height: 1px;
  background-color: #e4e4e4;
  width: 100%;
  position: absolute;
  top: 24px;
}

.common_contain {
  /* padding: 5px 1px; */
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}

.general_head {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* width: 200px; */
  padding-bottom: 3px;
  display: flex;
  height: 22px;
  align-items: center;
}

.color_picker {
  position: absolute;
  /* inset: 0; */
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);

  /*   
    width: 130px;
    border: 1px solid #00000047;©
    padding: 2px 3px;
    border-radius: 3px;
    height: 25px; */
}
.color_head {
  position: relative;
  height: 25px;
  width: 50px;
  background: red;
  overflow: hidden;
  border-radius: 5px;
}

.sub_container {
  display: flex;
  padding: 0px 0px 18px 0px;
  justify-content: space-between;
  width: 50vw;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 18px;
  align-items: baseline;
}
.upload_contain {
  width: 86px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 3px;
  display: flex;
  background: var(--bg-light-gray);
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.upload_txt {
  color: var(--second-text-color);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.upload_contain:hover {
  background: var(--hover);
  cursor: pointer;
}
.upload_contain:hover .upload_txt {
  color: #000000;
}

.menu_contain {
  padding: 16px 1px 1px 1px;
}
.menu_check_box {
  width: 18px;
  height: 18px;
}

.menu_check_box:checked {
  accent-color: #3454d1;
}

.name-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits text to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--main-text-color);
}
.menu_selection_contain {
  display: flex;
  margin-bottom: 15px;
}

/* --------------- */

.toggle-button-wrapper {
  position: relative;
  width: var(--toggle-width);
  height: var(--toggle-height);
}

.toggle-checkbox {
  opacity: 0;
  /* display: none; */
  cursor: pointer;
}

.toggle-button-wrapper input:disabled + .toggle-label {
  /* background-color: grey; */
  background-color: #4a6dc6bb;
  cursor: default;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s;
}

.toggle-checkbox:focus-visible ~ .toggle-label {
  outline: 2px solid currentColor;
  border-radius: 10px;
}

.toggle-handle {
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: calc(var(--toggle-height) - 5px);
  height: calc(var(--toggle-height) - 5px);
  background-color: var(--bg-primary);
  border-radius: 50%;
  transition: transform 0.3s;
  cursor: pointer;
}

.toggle-checkbox:checked + .toggle-label {
  background: var(--Base-Color);
}

.toggle-checkbox:checked + .toggle-label .toggle-handle {
  transform: translateX(var(--toggle-height));
}

/* end ------------------- */

.project_contain {
  padding: 16px 1px 1px 1px;
}

.project_sub_contain {
  display: flex;
  align-items: center;
  margin: 14px 0px;
}
.toggle-container {
  display: flex;
  align-items: center;
}

/* ============ */

.sticky_tab {
  position: sticky !important;
  top: 0;
  /* z-index: 100; */
  /* background: var(--bg-primary); */
}

.task_tabs {
  /* margin: 8px 0px; */
  position: relative;

  border-bottom: 0.5px solid var(--border-color);
  position: relative;
  /* padding: 8px 2px 0px 0px; */
  background: var(--bg-primary);
  border-radius: 8px;
  padding: 2px 8px;

  /* z-index: 100 !important; */
  /* z-index: 1; */
}

.tab_active {
  padding: 1px 1px 8px 1px;
  border-bottom: 2px solid #3454d1;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.tab_inactive {
  padding: 1px 1px 8px 1px;
  /* border-bottom: 1.6px solid red; */
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
}

.tab_inactive:hover {
  opacity: 1;
  cursor: pointer;
}

.settings-contain {
  padding: 16px 1px 1px 1px;
}

.slogan {
  border: none;
  height: 25px;
  width: 370px;
  outline: none;
  border: 1px solid #d1d1d1;
  padding: 1px 0px 1px 5px;
  font-size: 13px;
}

.fade-opacity {
  animation: fade_opacity 100ms ease-in-out;
}

.scale-up-center {
  -webkit-animation: scale-up-center 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fsdfa {
  background-color: rgb(114, 114, 114);
}

.view-center {
  display: flex;
  justify-content: center;
}

.file-upload {
  width: 45%;
  min-height: 25svh;
  background-color: var(--bg-primary);
  margin-top: 2%;
  border-radius: 1px;
  max-height: 80svh;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 5px;
}
.file-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0px 0px 0px;
}

.custom-upload-contain {
  border: 2px dashed #d2d2d2;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.drop-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.header-modal {
  font-size: 15px;
  font-weight: 500;
}

.upload-contain {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 13px 3px 8px 0px;
  border: 1px dashed #d2d2d2;
  padding: 3rem 5px 3rem 5px;
}

.choose_file {
  text-decoration: underline;
}
.upload-btn-contain {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-right: 2px;
  margin-bottom: 9px;
}
.upload_btn {
  border: none;
  border-radius: 5px;
  background-color: #d2d2d2;
  cursor: pointer;
  /* color: var(--bg-primary); */
  padding: 4px 40px 4px 40px;
}
/* .cancel-btn {
  border: none;
  border-radius: 5px;
  background-color: #ffff;
  cursor: pointer;
 
} */

.code_text {
  height: 246px;
  width: 50vw;
  outline: none;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  scrollbar-width: thin;
  font-family: monospace;
  opacity: 0.6;
  font-weight: normal;
}

.css-head {
  font-size: 15px;
  font-weight: 500;
}

.css-sub {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.7;
  margin-bottom: 10px;
}

.create-custom-head {
  opacity: 0.9;
  font-size: 14px;
  font-weight: 500;
}
.emp-create {
  display: flex;
  margin-bottom: 17px;
  align-items: center;
  gap: 7px;
  opacity: 0.7;
  cursor: pointer;
}
.emp-create:hover {
  opacity: 1;
}

.emp-modal_view {
  min-height: 40vh;
  width: 53vw;
  background: var(--bg-primary);
  border-radius: 2px;
  margin-top: 50px;
  max-height: 70vh;
  padding: 15px;
}
.emp-card {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.emp-create-contain {
  display: flex;
  padding: 22px 20px 10px 20px;
  height: 100%;
}
.field {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.left-side {
  flex: 1 1 0%;
  border-right: 1px solid #d1d1d1;
}

.emp-btn-contain {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 11px;
}
.file-cancel-btn {
  border: none;
  padding: 0px 10px;
  border-radius: 2px;

  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;

  border: 1px solid var(--border-color);
  height: 28px;
}

.default-btn {
  border: none;
  padding: 0px 10px;

  background: var(--Base-Color, #3454d1);
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: var(--bg-primary);
  border-radius: 8px;
  height: 28px;
}

.blue-btn {
  height: 28px;

  border: none;
  border-radius: 8px;

  background: var(--Base-Color);
  /* cursor: pointer; */
  gap: 3px;
  font-size: 13px;
  font-weight: 600;
  color: var(--bg-primary);
  padding: 0px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
}
.blue-button-inline-task {
  height: 30px;
  width: 72px;
  border: none;
  border-radius: 4px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  color: var(--bg-primary);
  justify-content: center;
}
.blue-button-inline-related-task {
  height: 23px;
  width: 29px;
  border: none;
  border-radius: 4px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  color: var(--bg-primary);
  justify-content: center;
}
.inline-task-cancel-button {
  display: flex;
  color: var(--second-text-color);
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 72px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--border-color);
  cursor: pointer;
  background-color: #ffff;
}
.inline-related-task-cancel-button {
  display: flex;
  color: var(--second-text-color);
  align-items: center;
  justify-content: center;
  height: 23px;
  /* width: 35px; */
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--border-color);
  cursor: pointer;
  background-color: var(--bg-light-gray);
}

.button-inline-related-task-font {
  font-size: 9px;
  font-weight: 400;
}

.outline-cancel-button {
  height: 28px;
  border: none;
  border-radius: 8px;
  gap: 3px;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-light-gray);
}

.create-btn {
  border: none;
  padding: 3px 18px;
  border-radius: 2px;
  background-color: #150000;
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: var(--bg-primary);
}

.create-text {
  font-size: 17px;
  font-weight: 600;
}
.choose-txt {
  font-size: 15px;
  font-weight: 600;
  opacity: 0.8;
  margin-top: 5px;
}

.single-card {
  height: 75px;
  width: 80px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.number {
  font-size: 16px;
  font-weight: 900;
}
input[type='radio']:checked + label .single-card {
  border: 2px solid blue; /* Adjust the border style as per your preference */
}

.selected-card {
  border: 2px solid rgb(0, 0, 0);
}

.emp-input {
  width: 90%;
  height: 35px;
  border: 1px solid #d1d1d1;
  outline: none;
  padding-left: 5px;
  font-size: 13px;
  /* margin-top: 5px; */
}

.field-type {
  font-size: 10px;
  position: absolute;
  top: -7px;
  left: 13px;
  background: var(--bg-primary);
  padding: 0px 5px 0px 5px;
  font-weight: 500;
  text-transform: capitalize;
}

.close-absolute {
  top: 2px;
  position: absolute;
  right: 11px;
  cursor: pointer;
  opacity: 0.4;
}
.close-absolute:hover {
  opacity: 1;
}
.emp-add {
  align-items: center;
  align-self: center;
  padding: 0px 10px 1px 10px;
  border-radius: 10px;
  line-height: 2px;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  margin-top: 15px;
}

.emp-add-contain {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  background-color: #141212;
  padding: 3px 13px;
  border-radius: 2px;
  color: var(--bg-primary);
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.emp-add-btn {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 3px;
  opacity: 0.6;
  margin-right: 3rem;
  cursor: pointer;
}

.emp-add-btn :hover {
  opacity: 1;
}

.field-head {
  display: flex;
  justify-content: space-between;
}

.emp-toggle-contain {
  display: flex;
  gap: 18px;
  align-items: center;
  margin-bottom: 18px;
}

.edit-delete-contain {
  display: none;
  gap: 15px;
}
.emp-toggle-contain:hover .edit-delete-contain {
  display: flex;
}

.custom-field-container {
  display: flex;
  gap: 15px;
}

.custom-field-container:hover .edit-delete-contain {
  display: flex;
}
.select-contain {
  display: flex;
  margin-bottom: 20px;
  gap: 30px;
}
.select-style {
  width: 120px;
  font-size: 14px;
  padding: 0px;
}
.select-sub {
  display: flex;
  align-items: flex-end;
}
.css-1xc3v61-indicatorContainer {
  /* padding: 4px 8px !important; */
  padding: 2px 4px !important;
}
/* .css-151sz6c-indicatorContainer{
  padding: 4px 8px !important;

} */
.css-15lsz6c-indicatorContainer {
  padding: 2px 4px !important;
}

.project-txt {
  font-size: 13px;
  opacity: 0.7;
  font-weight: 400;
}
.css-1fdsijx-ValueContainer {
  padding: 0px 4px !important;
}
.css-hlgwow {
  padding: 1px 4px !important;
}

.status-color-container .css-hlgwow {
  padding: 0 !important;
}

.chat-head {
  font-size: 14px;
  width: 265px;
  color: black;
  opacity: 0.6;
  font-weight: 500;
}

.file-card-contain {
  display: flex;
  background: var(--bg-light-gray);
  margin: 0px 15px;
  padding: 7px 8px;
  margin-bottom: 10px;
  position: relative;
}
.icon-outer {
  background: #e1e1e1;
  border-radius: 100px;
  padding: 7px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-contain {
  display: flex;
  align-items: center;
}
.details-contain {
  margin-left: 10px;
  overflow: hidden;
  margin-right: 20px;
}
.file-head {
  font-size: 15px;
  font-weight: 600;
}
.file-size {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
}
.selected-file {
  margin-top: 10px;
  margin-bottom: 15px;
  /* display: block; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.file-close {
  position: absolute;
  right: 15px;
  top: 14px;
  cursor: pointer;
  opacity: 0.8;
}

.open-image {
  display: flex;
  /* align-items: center; */
  margin-top: 2.5px;
  /* padding: 0px; */
  margin-left: 10px;
  cursor: pointer;
}
.outlet-contain {
  height: 79svh;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.add_item_button {
  font-size: 14px;
  color: #879096;
  border-radius: 5px;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border: none;
}

.list_top_card {
  background-color: #f2f2f2;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  height: 34px;
  display: flex;
  align-items: center;
  color: #879096;
  padding: 4px 15px 4px 15px;
  border: none;
  margin: auto;
}

.add_item_button:hover {
  background-color: #f2f1f1;
}
.list_top_card:hover {
  background-color: #dbdbdb;
}
.ptr {
  cursor: pointer;
}
/* color: var(--bg-primary); */

/* css for creatable async select  */

.container {
  position: relative; /* Ensure container has a relative position */
}

.border-box-select {
  height: 33px;
  position: relative;
  border: 1px solid rgb(191, 193, 193);
  border-radius: 3px;
  padding: 7px 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
  width: 100%; /* Ensure it takes up the full width of its container */
}

.select-box-input-text {
  border: none;
  height: -webkit-fill-available;
  width: 88%;
  border-right: 1px solid rgb(191, 193, 193);
}

.select-box-input-text:focus-visible {
  outline: none;
}

.selection-box-arrow {
  color: rgb(191, 193, 193);
}

.select-option-box {
  margin-top: 4px;
  /* overflow: scroll; */
  overflow: auto;
  top: 100%;
  max-height: 200px;
  border: 1px solid rgb(191, 193, 193);
  border-radius: 3px;
  padding: 7px 0;
  position: absolute; /* Position the box absolutely */
  width: 100%; /* Match the width of the parent */
  background-color: var(--bg-primary); /* Ensure the background is white */
  z-index: 10; /* Ensure it appears above other elements */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for popup effect */
}
.selection-option {
  /* padding: 5px 13px; */
  height: auto;
  min-height: 25px;
  flex-wrap: wrap;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selection-option-first {
  width: 87%;
  overflow-wrap: break-word; /* Ensures words will break at spaces first */
  word-break: break-word; /* Ensures long words will break */
  white-space: normal; /* Ensures text wraps normally */
}

/* .selection-option:hover {
  background-color: #e7e7e7;
} */

.edit-icon {
  visibility: hidden;
  color: rgb(7, 12, 21);
  width: 10%;
}

.selection-option:hover .edit-icon {
  visibility: visible;
}

.selection-option.highlighted {
  background-color: #d3d3d3; /* Highlight color for selected item */
}

.edit-icon:hover {
  border-bottom: 1px solid;
  /* color: rgb(7, 12, 21); */
}

.select-box-input-text:focus ~ .inp-msg {
  display: block !important;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--main-text-color);
  background-color: transparent;
  border: none;
  margin-top: 0.3em;
  height: 34px;
  border-radius: 8px;
  box-shadow: var(--input-border-shadow);
}
.form-control:focus {
  color: var(--main-text-color);
  outline: none;
  background-color: transparent;
  box-shadow: var(--input-border-shadow);
}

.input-field {
  font-size: 12px;
  min-height: 30px;
}

/* FOr stacked circular images */
.ml-neg-15 {
  margin: auto;
  margin-left: -15px !important;
}
.member-name-container {
  display: grid !important;
  min-width: max-content;
  bottom: calc(100% + 3px);
  position: absolute;
  align-items: center;
  grid-template-columns: 0fr;
  align-self: center;

  background-color: #282828;
  font-weight: 500;
  color: var(--bg-primary);
  width: -moz-fit-content;
  height: fit-content;
  width: fit-content;
  font-size: 12px;
  border-radius: 3px;
}

.avatar-tooltip-bottom {
  bottom: -30px;
  z-index: 999;
}
.avatar-tooltip-c-bottom {
  top: calc(100% + 30px);
  z-index: 999;
}
.avatar-tooltip-top {
  bottom: calc(100% + 5px);
}

.avatar-container {
  display: flex;
}

.member-avatar {
  outline: 2px solid var(--bg-primary);
}

.z-1 {
  --z-index: 10;
}
.z-2 {
  --z-index: 20;
}
.z-3 {
  --z-index: 30;
}

.avatar-active {
  --z-index: 20;
  outline: 2px solid var(--color-light-blue) !important;
  z-index: var(--z-index) !important;
  position: relative;
}

.member-avatar:hover {
  outline: 2px solid var(--bg-primary);
  z-index: 10;
  position: relative;
  cursor: pointer;
}
.member-avatar:hover + .member-name-container,
.member-name-container:hover {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  margin-right: 18px;
  grid-template-columns: 1fr;
  padding-inline: 7px;
  transition: grid-template-columns 200ms ease-in-out 500ms, padding-inline 200ms ease-in-out 550ms;
}

/* Stacked circular avatar end */

/* Tooltip start */

.hrv-tooltip {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  color: var(--bg-primary);
  background-color: #000000d7;
  font-weight: 400;
  padding: 3px 10px;
  border-radius: 3px;
  z-index: 10;
  font-size: 13px;
}

.hr-tooltip-container:hover > .hrv-tooltip {
  visibility: visible;
  z-index: 999;
  opacity: 1;
  transition: opacity 200ms ease-in-out 300ms;
}

.hr-tooltip-container:hover {
  border-radius: 50%;
  /* background: #b7b3b34a; */
}

.w-fit {
  width: fit-content;
}

.hr-tooltip-container {
  cursor: pointer;
  position: relative;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: fit-content; */
}

.cursor-pointer {
  cursor: pointer;
}
.lucide-circle-play polygon {
  fill: var(--main-text-color);
}

.lucide-circle-stop polygon {
  fill: var(--main-text-color);
}
/* Can be used with animated grid-template-columns */
.delay-overflow-visible {
  overflow: hidden;
  animation: overflowVisible 0ms ease-in-out 450ms forwards;
}

.start-auto {
  right: auto !important;
}
.end-auto {
  left: auto !important;
}

.unset-btn {
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: none;
}

.row-gap-15px {
  row-gap: 15px;
}

.z-index-100 {
  z-index: 100 !important;
}

.fz-12px,
.fz-12px-em {
  font-size: 0.75em;
}

.fz-13px,
.fz-13px-em {
  font-size: 0.8125em;
}

.fz-14px,
.fz-14px-em {
  font-size: 0.875em !important;
}
.fz-15px,
.fz-15px-em {
  font-size: 0.9375em !important;
}
.fz-16px,
.fz-16px-em {
  font-size: 1em !important;
}
.fz-17px,
.fz-17px-em {
  font-size: 1.0625em !important;
}
.fz-18px,
.fz-18px-em {
  font-size: 1.125em !important;
}

/* REM */
.fz-10px-rem {
  font-size: 0.625rem;
}
.fz-11px-rem {
  font-size: 0.6875rem;
}

.fz-13px-rem {
  font-size: 0.8125rem;
}

.fz-14px-rem {
  font-size: 0.875rem !important;
}

.fz-15px-rem {
  font-size: 0.9375rem !important;
}

.fz-16px-rem {
  font-size: 1rem !important;
}
.fz-17px-rem {
  font-size: 1.0625rem !important;
}
.fz-18px-rem {
  font-size: 1.125rem !important;
}

.small-gret-text {
  color: grey;
  font-size: 12px;
}

/* input box style  */
.input-box {
  width: 100%;
  border: none;
  border-radius: 8px;
  height: 34px;
  padding: 0.375rem 0.75rem;
  background-color: var(--bg-primary);
  font-size: 13px;
  line-height: 17.64px;
  outline: none;
  border: 1px solid var(--border-color);
}

.input-box:focus {
  border: 1px solid #3454d1;
  outline: 3px solid rgba(52, 84, 209, 0.25); /* Use outline instead of box-shadow */
}

.input-box::placeholder {
  color: var(--second-text-color); /* Placeholder color */
}
.input-error {
  border: 1px solid red;
}
.input-error:focus {
  border: 1px solid #ea4d4d;
  outline: 3px solid rgba(255, 0, 0, 0.25); /* Use outline instead of box-shadow */
}
.label_input,
.form-input-label {
  font-size: 13px;
  font-weight: 500;
  margin-top: 0.5em;
  color: var(--main-text-color);
  margin-bottom: 0.25rem;
}
.label_input_gray {
  font-size: 13px;
  font-weight: 600;
  margin-top: 0.5em;
  color: var(--second-text-color);
  margin-bottom: 0.25rem;
}
/* font size 13px  */
.fs-13 {
  font-size: 13px;
  line-height: 16.38px;
}
.fade-entry {
  opacity: 0;
  animation: fadeInTemp 200ms ease-in-out 100ms forwards;
  /* box-shadow: var(--box-border-shadow); */
}

@keyframes fadeInTemp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-left-entry {
  opacity: 0;
  animation: fadeLeftEntry 200ms ease-in-out 100ms forwards;
}

@keyframes fadeLeftEntry {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fade-right-entry {
  opacity: 0;
  animation: fadeRightEntry 350ms ease-in-out forwards;
}

@keyframes fadeRightEntry {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  40% {
    /* transform: translateX(10px); */
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/* css for heading url  */

/* .url-container-new {
  display: flex;
  height: 32px;
  padding: 8px 15px 8px 15px;
  border-bottom: 1px solid #00000021;
} */

.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-primary);
  border-radius: 6px;
  height: 30px;
  width: 38px;
  border: 1px solid #0c0b0b14;
  color: grey;
  cursor: pointer;
}

.form-input-label {
  margin-bottom: 0.25rem;
  font-size: var(--regular-font);
  font-weight: 500;
}
/* input box style  */
/* .input-box {
  width: 100%;
  border: 0.5px solid var(--second-text-color);
  border-radius: 3px;
  height: 28px;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 17.64px;
}
.input-box:focus {
  border: 2px solid blue;
  outline: none; 
}

.input-box::placeholder {
  color: var(--second-text-color);
} */

/* font size 13px  */
.fs-13 {
  font-size: 13px;
  line-height: 16.38px;
}

.action-pop-up {
  position: fixed;
  bottom: 82px;
  left: 25px;
  background-color: var(--bg-primary);
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
  animation: fadeIn-pop-up 100ms ease-in-out;
  z-index: 999999;
  max-width: 300px;
}
@keyframes fadeIn-pop-up {
  from {
    scale: 0.8;
    opacity: 0.5;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}

/* date picker css */
.date-picker {
  font-size: 13px;
  border: 1px solid var(--border-color, #eaebef);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 6.5px 8px;
  background: var(--bg-primary);
  font-weight: 600;
  cursor: pointer;
  color: var(--second-text-color);
}

.date-picker:hover {
  border: 1px solid var(--second-text-color);
}

/* heder organization  */

.org-container {
  border: 1px solid gray;
  border-radius: 4px;
  padding: 2px 12px;
  cursor: pointer;
  position: relative;
}

.global-add-list-text {
  text-decoration: none;
  color: var(--main-text-color);
  display: flex;
  align-items: center;
}
.global-add-list-text :hover {
  background-color: #f0f1f3;
  color: var(--main-text-color);
}
a.global-add-list-text:hover {
  text-decoration: none !important;
  color: var(--main-text-color) !important; /* Optional: to avoid blue */
}
.global-add-list-item :hover {
  background-color: var(--border-color);
}

.rdrMonthAndYearWrapper,
.rdrDateDisplayWrapper,
.rdrMonth,
.rdrMonthsVertical {
  width: 285px !important;
}

.rdrMonth {
  padding: 0;
}
.rdrNextPrevButton {
  margin: 0;
}
.rdrMonthAndYearWrapper {
  height: 40px !important;
}
.popover-item .search_contain input {
  display: block !important;
}
.ai-header-text {
  font-size: 13px;
}
@media (max-width: 768px) {
  .img-calendar {
    display: none;
  }
  .task_tabs {
    padding: var(--top-breadcrumb-padding-responsive);
  }
  .search_contain {
    width: 100% !important;
  }
  .file-upload {
    width: 90%;
  }
  .pagination_parent_contain {
    padding: 0px 17px;
  }
  .rdrMonthAndYearWrapper,
  .rdrDateDisplayWrapper,
  .rdrMonth,
  .rdrMonthsVertical {
    width: auto !important;
  }
  .ai-header-text {
    display: none;
  }
}

.page-container {
  margin: 16px;
  background-color: var(--bg-primary);
  padding: 20px;
}
.page-head {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
}
.breadcrumbs-name {
  max-width: 40vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-preview-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
  padding: 1px;
}

/* css for delete  */
.delete-container {
  width: min(400px, calc(100% - 20px));
  background: var(--bg-primary);
  border-radius: 4px;
  margin-top: 50px;
  /* max-height: 70vh; */
  /* padding: 15px; */
}
.delete-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-highlight {
  color: var(--icon-light);
  font-weight: 600;
}
/* reminder pop */

.reminder-pop-container {
  min-height: 60px;
  /* height: 60px; */
  width: 370px;
  position: absolute;
  bottom: 50px;
  right: 20px;

  border-radius: 5px;
  background: var(--bg-primary);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideInBottom 0.5s ease-out;
}
.reminder-head {
  display: flex;
  justify-content: space-between;
  padding: 6px 8px;
}
.reminder-bell-contain {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

.reminder-pop-container.exit {
  animation: slideOutBottom 0.1s ease-out forwards;
}

@keyframes slideInBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutBottom {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
/* reminder pop */

/* email input  */
.email-input-container {
  border: 1px solid var(--input-border, #d1d1d1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.entered-email-container {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  max-width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
}

.entered-mail-text {
  margin-right: 8px;
  max-width: 100%;
  word-break: break-word; /* Ensure long emails break to the next line */
  overflow-wrap: break-word;
  color: var(--text-color);
}

.remove-mail-icon {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  display: flex;
}

/* .mail-input {
  flex: 1;
  border: none;
  outline: none;
  min-width: 150px;
}
.edit-email-input:focus-visible {
  border: none;
  border-radius: 4px;
  outline: none;

} */

.delete-modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blue-btn .button_txt {
  color: #fff;
}
.default-btn .button_txt {
  color: #fff;
}

.table-head-color {
  color: var(--second-text-color);
}
