/* AddWidgetModal.css */

.widget-overlay {
  height: 100vh;
}

.add-widget-heading {
  color: var(--text-dark);
  margin: 0;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.modal-container {
  background: var(--bg-primary);
  width: 304px;
  height: 1076px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.modal-container h3 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
}
/* 
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.form-group select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
} */

.columns-options {
  display: flex;
  gap: 8px;
}

.columns-options button {
  flex: 1;
  background-color: var(--bg-primary);
  width: 83px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 0.5px solid var(--empty-state, #d1d5d7);
  color: #c6c6c6;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.columns-options .active {
  background: var(--Base-Color);
  color: var(--bg-primary);
}

.radio-group {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.radio-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-group input {
  margin-right: 6px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: sticky;
  bottom: 0px;
}

.menu-item-outline-widget {
  height: 20px;
  width: 20px;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.menu-item-outline-widget:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.widget-label {
  color: var(--main-text-color, #07082f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.widget-label-radio {
  color: var(--text-dark);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.h-90vh {
  height: 90vh;
}

/* deadline css */
.deadlines {
  overflow-y: auto;
  width: 100%;
}
.dashboard-deadline-container {
  padding: 14px 7px 0px 7px;
}

.dashboard-deadline-progress {
  background-color: var(--hover);
  width: 100%;
  border-radius: 20px;
}

.deadline-text {
  color: var(--incomplete, #ea4d4d);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}

.deadline-info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--divider);
}

.deadline-owner-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
}

.deadline-date-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
  align-items: flex-end;
}

.deadline-task-heading {
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard-status-widget {
  /* display: flex;
  flex-wrap: wrap;
  gap: 0.25rem; */
  display: flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
  overflow: hidden;
}
.dashboard-status-widget:hover {
  overflow: auto;
  scrollbar-width: none;
}

.widget-format-option-label {
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.widget-format-option-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.widget-drop-icon-container {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #266df01a;
}

.drop {
  top: 2.25rem;
}
.dashboard-widget-dropdown {
  width: 100px;
  height: auto;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--bg-primary);
  position: relative;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* top: 20px;
  right: -24px; */
}
.dashboard-widget-dropdown-item {
  padding: 3px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.dashboard-widget-dropdown-item:hover {
  background-color: var(--border-color);
  cursor: pointer;
}
/* task statistics*/

.popover-statics {
  border-radius: 4px;
  background: var(--bg-primary);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.popover-statics-heading {
  color: var(--main-text-color, #07082f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.popover-statics-content-text {
  color: var(--second-text-color, #67677f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* dashboard projects*/

.dashboard_project-content {
  display: flex;
  flex-direction: column;
}

.dashboard_project-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  /* padding: 6px; */
  overflow: auto;
  padding-bottom: 65px;
}
.dashboard_project-card {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
}
.dashboard_project-card:hover {
  background: var(--bg-light-gray);
  cursor: pointer;
}

.dashboard_project-icon {
  background-color: #d9eaff;
  color: #0066ff;
  font-weight: bold;
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
}
.dashboard_project-name {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dashboard_project-tasks-count {
  color: var(--second-text-color, #67677f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_project-status {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.dashboard_project-status-dot {
  width: 8px;
  height: 8px;
  background-color: #ffcc00;
  border-radius: 50%;
  margin-right: 8px;
}
.dashboard_project-end-of-list {
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  padding: 5px;
}

/* taskreport */

.task-report {
  width: 100%;
  background: var(--bg-primary);
  border-radius: 8px;
  height: auto;
  overflow: scroll;
  margin-bottom: 70px;
}

.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.task-header h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.chart-icon {
  cursor: pointer;
}

.task-table-header {
  display: flex;
  background: #f5f5f5;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #666;
  border-radius: 6px;
}

.task-row {
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid #e0e0e0;
}

.task-row:last-child {
  border-bottom: none;
}

.column {
  flex: 1;
  text-align: center;
}

.column.users {
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  flex: 2;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

/* template report */

.template-report {
  width: 100%;
  background: var(--bg-primary);
  border-radius: 8px;
  padding: 5px;
  position: relative;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.report-header h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.header-icons {
  display: flex;
  gap: 10px;
}

.chart-icon,
.more-icon {
  cursor: pointer;
}


.table-row {
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid #e0e0e0;
}

.table-row:last-child {
  border-bottom: none;
}

.column.templates {
  text-align: left;
  flex: 2;
}

/* Floating Action Button */
/* .fab {
  position: absolute;
  bottom: -15px;
  right: -15px;
  width: 40px;
  height: 40px;
  background: #1e90ff;
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab:hover {
  background: #0f75c4;
} */

/* working hours */

.chart-container {
  width: 450px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  font-family: Arial, sans-serif;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.chart-header h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.dropdown {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.bar-chart {
  position: relative;
  padding: 10px;
}

.user-labels {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.hours-label {
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

/* Sales report  */
.sales-report-container {
  width: 600px;
  background: var(--bg-primary);
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.report-header h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.dropdown {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.chart-wrapper {
  position: relative;
  height: 250px;
}

/* customers */
.customers-container {
  width: 100%;
  background: var(--bg-primary);
  border-radius: 8px;
  overflow: scroll;
  margin-bottom: 80px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.header h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.dropdown {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.tabs {
  display: flex;
  border-bottom: 2px solid #e5e7eb;
  margin-bottom: 12px;
}

.tab {
  font-size: 14px;
  padding: 6px 10px;
  cursor: pointer;
  color: #666;
}

.tab.active {
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
}

.table-container {
  width: 100%;
}


.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f5f5f5;
}

.client-info {
  display: flex;
  align-items: center;
}

.client-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
  margin-right: 8px;
}

.client-name {
  font-size: 13px;
  /* color: #333; */
}

.total-hours {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
/* performance */

.performance-container {
  width: 100%;
  background: var(--bg-primary);
  border-radius: 8px;
  /* padding: 5px; */
  overflow: auto;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.report-header h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.menu-icon {
  font-size: 18px;
  cursor: pointer;
  color: #666;
}

.table-container {
  width: 100%;
}

.table-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  color: #888;
  padding: 8px 0;
  border-bottom: 1px solid #e5e7eb;
  background-color: var(--bg-light-gray);
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
}

.user-name {
  font-size: 14px;
  color: #333;
}

.due-tasks,
.avg-hour,
.completed,
.sub-tasks {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.avg-hour.red {
  color: red;
}

.avg-hour.orange {
  color: orange;
}

.avg-hour.green {
  color: green;
}

@media (max-width: 768px) {
  .dashboard-status-widget {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
