/* dashboard.css */

/* Main container for the dashboard */
.dashboard-container {
  margin-top: 10px;
  padding: 15px;
  background-color: #f8f9fa; /* Light background color */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.widget {
  display: flex;
  height: inherit;
  width: 100%;
}

/* Layout styling for the React-Grid-Layout */
.layout {
  display: grid;
  gap: 10px;
}

/* Individual grid items/widgets */
.grid-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: var(--bg-primary);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.2s ease;
}

/* Hover effect on widgets for better interaction */
.grid-item:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

/* Dashboard header with navigation */
.dashboard-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  cursor: move;
  /* height: 25px; */
}

/* Header text styling */
.dashboard-widget-header {
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
  visibility: hidden;
}
.react-grid-item:hover > .react-resizable-handle.react-resizable-handle-nw {
  visibility: visible;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
  visibility: hidden;
}
.react-grid-item:hover > .react-resizable-handle.react-resizable-handle-se {
  visibility: visible;
}
.menu-item-outline {
  padding: 5px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}
.menu-item-outline.active {
  background: var(--bg-primary);
  box-shadow: var(--button-shadow, 0px 0px 5px rgba(0, 0, 0, 0.1));
}

.menu-item-outline:hover {
  background-color: var(--border-color);
}

/* React-Grid-Layout specific item styling */
.react-grid-item {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease; /* Smooth repositioning */
  overflow: hidden;
}

/* Handle for resizing widgets */
.react-resizable-handle {
  width: 10px;
  height: 10px;
  /* background-color: #ddd;
    border-radius: 50%; */
  cursor: se-resize;
}

/* Resizable widget styling */
.react-resizable {
  padding: 15px;
  border: 1px solid var(--divider);
  border-radius: 10px;
  background-color: var(--bg-primary);
  transition: background-color 0.3s ease;
  z-index: 9;
}

/* Adjust size and padding when resizing */
.react-resizable:hover {
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.15); */
}

/* Ensuring the header section looks neat */
.notification-nav-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 8px; */
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.dashboard_menu_active {
  font-weight: bold;
  color: var(--main-text-color);
  display: flex;
  align-items: center;
}
.editable-dashboard-window {
  display: flex;
  gap: 0.25rem;
}
.editable-dashboard-window .sett-save-btn {
  margin-left: 0;
}
.taskAdd-FAB {
  width: 60px;
  height: 60px;
  background-color: #305ccb;
  cursor: pointer;
  border: none;
  position: fixed;
  right: 20px;
  bottom: 40px;
  border-radius: 50%;
  z-index: 999;
}
.widget-form-container {
  height: 100%;
  width: 20rem;
  background: var(--bg-primary);
}

.layout-selection-container {
  display: flex;
  padding: 10px 24px 0px 24px;
  justify-content: flex-end;
}
.dashboard-status-count-container {
  /* width: 180px;
  height: 71px; */
  width: 160px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px dashed var(--divider-dark, #f0f0f0);
  background: var(--bg-primary);
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 12px;
  cursor: pointer;
}
.status-count-container {
  display: flex;
  flex-direction: column;
}
.status-count-text {
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.common-status-round {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-text {
  color: var(--second-text-color, #87909e);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.completed {
  background: #1ca9771a;
}
.inprogress {
  background: #ff8a001a;
}
.pending {
  background: #00b2ff1a;
}
.toverify {
  background: #6f00ff1a;
}
.underverification {
  background: #fa00ff1a;
}
.failed {
  background: #ea54401a;
}
.status-container {
  padding: 20px 20px 0px 20px;
}
.status-count-text-center {
  color: var(--text-dark);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.status-name-text-center {
  color: var(--text-dark);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.status_count-text-container {
  position: relative;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.status-tooltip {
  width: 45px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--bg-primary);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
}
.status-tooltip-text {
  color: var(--main-text-color, #07082f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.order-selection {
  padding: 0px 18px;
  cursor: pointer;
  font-size: 12px;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 10px;
  }

  .dashboard-widget-header {
    font-size: 0.9rem;
  }

  .grid-item {
    padding: 10px;
  }

  .menu-item-outline {
    padding: 4px;
  }
  .widget-form-container {
    width: 100%;
  }
  .status_count-text-container {
    top: -40%;
  }
}

/* .dashboard-chat-container .list_condainer {
  height: calc(100% - 80px) !important;
}

.dashboard-chat-container .list_condainer div {
  height: 100%;
} */

.dashboard-chat-container .list_condainer {
  height: calc(var(--grid-height) - 125px);
  max-height: calc(var(--grid-height) - 125px);
}
